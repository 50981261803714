export default [
	{
		path: '/',
		redirect: '/index'
	},
	{
    path: '/index',
    component: (resolve) => require(['../views/index.vue'], resolve)
	},
	{
    path: '/signUp',
    component: (resolve) => require(['../views/signUp.vue'], resolve)
	},
	{
		path: '/records',
		component: (resolve) => require(['../views/records.vue'], resolve)
	}
]